<template>
    <header class="main-header">
        <div class="uk-position-relative main-header__img">
            <div class="uk-position-top">
                <nav class="uk-navbar uk-navbar-container uk-margin uk-navbar-transparent uk-flex uk-flex-center uk-padding uk-flex-top">
                    <div class="uk-navbar uk-margin-auto-right uk-width-1-3">
                        <a class="uk-navbar-toggle uk-text-emphasis uk-text-lead main-header__burger"
                            uk-toggle="target: #offcanvas-nav" href="#">
                            <span uk-navbar-toggle-icon></span> <span class="uk-margin-small-left">Меню</span>
                        </a>
                    </div>

                    <div class="uk-navbar uk-width-1-3">
                        <div class="uk-light uk-align-center">
                            <a class="uk-logo main-header__logo uk-align-center" href="#">
                                <img src="../../assets/img/logo-inverse.png" alt="" width="70" height="auto">
                                <img class="uk-logo-inverse" src="../../assets/img/logo.png" alt="" width="70" height="auto">
                            </a>
                        </div>
                    </div>

                    <div class="uk-navbar uk-visible@m uk-margin-auto-left uk-width-1-3 uk-flex uk-flex-right">
                        <ul class="uk-list uk-flex uk-flex-right uk-flex-column">
                            <li>
                                <a class="uk-button uk-button-secondary uk-text-large"
                                    uk-tooltip="title: Позвонить; pos: left"
                                    :href="`tel:${this.$contacts.phone.split('-').join('')}`">{{this.$contacts.phone}}</a>
                            </li>
                            <li>
                                <a class="uk-button uk-button-secondary uk-text-large"
                                    uk-tooltip="title: Написать; pos: left"
                                    :href="`mailto:${this.$contacts.email}`">{{this.$contacts.email}}</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div class="uk-overlay uk-position-center">
                <h1 class="uk-heading-small uk-align-center">
                    {{title}}
                </h1>
                <a class="uk-button uk-button-primary uk-position-bottom-center" href="#modal-sections" uk-toggle>{{buttonText}}</a>
            </div>
        </div>

    </header>
</template>

<script>
export default {
    name: 'MainHeader',
    data () {
        return {
            title: 'Производство высотных работ',
            buttonText: 'Заказ услуг'
        }
    }
}
</script>

<style>

</style>