<template>
    <main>
        <Who/>

        <!-- <Special/> -->

        <Services/>

        <Advantages/>

        <Reviews/>

        <Contacts/>
    </main>
</template>

<script>
import Who from './content/Who.vue'
// import Special from './content/Special.vue'
import Contacts from './content/Contacts.vue'
import Reviews from './content/Reviews.vue'
import Advantages from './content/Advantages.vue'
import Services from './content/Services.vue'

export default {
    name: 'MainContent',
    components: {
        Services,
        // Special,
        Advantages,
        Reviews,
        Contacts,
        Who
    }

}
</script>

<style>

</style>