<template>
    <div class="uk-section section-reviews" id="reviews">
        <div class="uk-container">
            <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1 uk-margin" uk-grid>
                <div uk-scrollspy="cls: uk-animation-fade; repeat: true">
                    <div class="uk-card-header">
                        <h3 class="uk-card-title">{{title}}</h3>
                    </div>
                    <div class="uk-card-body">
                        <div class="uk-slider-container-offset" uk-slider>

                            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                                <ul class="uk-slider-items uk-child-width-1-1 uk-grid">
                                    <li
                                    v-for="(item, i) in reviews"
                                    :key="i">
                                    >
                                        <div class="uk-card uk-card-default">
                                            <div class="uk-card-body">
                                                <p class="uk-text-light uk-text-italic">{{item.text}}</p>
                                                <hr>
                                                <div class="uk-align-right">
                                                    <p class="uk-text-bold">{{item.person}}<br>
                                                        <span class="uk-text-light">{{item.position}}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous
                                    uk-slider-item="previous"></a>
                                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next
                                    uk-slider-item="next"></a>

                            </div>

                            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

                        </div>
                    </div>
                    <div class="uk-card-footer">
                        <div class="uk-align-center uk-flex uk-flex-center uk-flex-wrap">
                            <p class="uk-margin">
                                <a class="uk-button uk-button-default uk-margin-bottom" href="#modal-sections"
                                    uk-toggle>Заказ услуг</a>
                                <a class="uk-button uk-button-primary uk-margin-bottom"
                                    :href="`tel:${this.$contacts.phone.split('-').join('')}`">Позвонить</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reviews',

    data () {
        return {
            title: 'Отзывы',
            reviews: [
                {
                    text: `ТСЖ "Дачное 2" выражает благодарность ИП Агапову Д.К. ТСЖ неоднократно заказывало работы по ремонту и покраске фасада дома. За прошедшие годы совместной работы ни разу не возникало разногласий, все работы выполнялись в оговоренные сроки и без замечаний<br> Летом 2021 г. был отремонтирован и покрашен фасад дома, работа сделана качественно и оговоренные сроки, жители дома очень довольны`,
                    person: 'А. В. Пономаренко',
                    position: 'Председатель правления ТСЖ "Дачное 2"'
                },

                {
                    text: `С 2015 года все работы по ремонту фасада и кровли выполняются силами этого подрядчика. Обязательства выполняют, цены не заламывают. Рекомендую`,
                    person: 'Богомолов Ю.К.',
                    position: 'Генеральный директор УК "СЗРСУ"'
                },

                {
                    text: `ИП Агапов Д.К. производил на
                            фасаде нашего дома работы по устройству навесного вентилируемого фасада, штукатурные работы, а также монтаж отливов окон и балконов. Нареканий ни по ходу выполнения ни по результат работ нет. Продолжаем сотрудничество`,
                    person: 'Луженков И.В.',
                    position: 'Генеральный директор УК "Друза"'
                },

                {
                    text: `Настоящим письмом подтверждаем, что в процессе неоднократного сотрудничества с ИП Агапов Д.К. ("Промальп-центр") данная компания зарекомендовала себя как надежного, добросовестного и профессионального подрядчика. Все работы проводились в строгом соответствии с нормами и требованиями охраны труда, а так же с соблюдением договорных сроков. Сотрудники компании оснащены исправным и современным оборудованием для проведения высотных работ методом промышленного альпинизма`,
                    person: 'Пичужкин И.А.',
                    position: 'Исполнительный директор "Илрос монтаж"'
                }
            ]
        }
    }

}
</script>

<style>

</style>