<template>
    <div id="offcanvas-nav" uk-offcanvas="overlay: true">
        <div class="uk-offcanvas-bar">
            <button class="uk-offcanvas-close" type="button" uk-close></button>
            <ul class="uk-nav uk-nav-default left-side-nav">
                <li><a href="#who" uk-scroll>О нас</a></li>
                <li><a href="#special" uk-scroll>Специальное предложение</a></li>
                <li><a href="#services" uk-scroll>Услуги</a></li>
                <li><a href="#advantages" uk-scroll>Почему мы</a></li>
                <li><a href="#reviews" uk-scroll>Отзывы</a></li>
                <li><a href="#contacts" uk-scroll>Контакты</a></li>
                <hr>
                <li><a :href="`mailto:${this.$contacts.email}`">Написать<br>{{this.$contacts.email}}</a></li>
                <li><a :href="`tel:${this.$contacts.phone.split('-').join('')}`">Позвонить<br>{{this.$contacts.phone}}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>