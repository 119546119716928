import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import './assets/css/uikit.min.css'

Vue.config.productionTip = true
Vue.prototype.$contacts = {
  phone: '+7-965-029-96-36',
  email: 'agapovar.kam@mail.ru',
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
