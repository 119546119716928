<template>
    <footer class="main-footer">
        <div class="uk-section footer-wrap">
            <div class="uk-child-width-expand@s uk-text-center" uk-grid>
                <div>
                    <a class="uk-logo main-header__logo" href="#">
                        <img src="../../assets/img/logo-inverse.png" alt="" width="70" height="auto">
                        <img class="uk-logo-inverse" src="../../assets/img/logo.png" alt="" width="70" height="auto">
                        <span>Промальп центр</span>
                    </a>
                </div>
                <div>
                    <ul class="uk-nav uk-align-center">
                        <li class="uk-align-left@m"><a class="main-footer__nav-link" href="#who" uk-scroll>О нас</a>
                        </li>
                        <li class="uk-align-left@m"><a class="main-footer__nav-link" href="#services"
                                uk-scroll>Услуги</a></li>
                        <li class="uk-align-left@m"><a class="main-footer__nav-link" href="#advantages" uk-scroll>Почему
                                мы</a></li>
                        <li class="uk-align-left@m"><a class="main-footer__nav-link" href="#reviews"
                                uk-scroll>Отзывы</a></li>
                        <li class="uk-align-left@m"><a class="main-footer__nav-link" href="#contacts"
                                uk-scroll>Контакты</a></li>
                    </ul>
                </div>
                <div>
                    <ul class="uk-list uk-iconnav uk-iconnav-vertical">
                        <li><a class="uk-link-heading uk-text-large" :href="`mailto:${this.$contacts.email}`">{{this.$contacts.email}}</a>
                        </li>
                        <li><a class="uk-link-heading uk-text-large" :href="`tel:${this.$contacts.phone.split('-').join('')}`">{{this.$contacts.phone}}</a></li>
                    </ul>

                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>