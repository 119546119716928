<template>
    <div>
        <div class="uk-h3">{{title}}</div>

        <div class="uk-slider-container-offset" uk-slider>

            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                <ul class="uk-slider-items uk-child-width-1-2@s uk-grid">
                    <li
                    v-for="(item, i) in items"
                    :key="i">
                        <div class="uk-card uk-card-default">
                            <div class="uk-card-media-top">
                                <img class="uk-height-medium prev-img" :src="item.mainImg" alt="">
                                <div uk-lightbox="animation: slide">
                                    <a class="uk-button show-all-photo uk-margin-top-small uk-align-center"
                                        :href="item.mainImg" data-caption="">Смотреть все фото объекта</a>
                                    <a
                                    v-for="(photo, i) in item.photos"
                                    :key="i"
                                    class="" 
                                    :href="photo" 
                                    data-caption=""></a>
                                </div>
                            </div>
                            <div class="uk-card-body">
                                <h3 class="uk-card-title">{{item.title}}</h3>
                                <p>{{item.description}}</p>
                            </div>
                        </div>
                    </li>
                </ul>

                <a class="uk-slidenav-large uk-position-center-left uk-position-small" href="#" uk-slidenav-previous
                    uk-slider-item="previous"></a>
                <a class="uk-slidenav-large uk-position-center-right uk-position-small" href="#" uk-slidenav-next
                    uk-slider-item="next"></a>

            </div>

            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Portfolio',

    data () {
        return {
            title: 'Последние выполненные работы',
            items: [
                {
                    mainImg: require('../../../assets/img/sennaya1.webp'),
                    photos: [
                        require('../../../assets/img/sennaya2.webp'),
                        require('../../../assets/img/sennaya3.webp'),
                        require('../../../assets/img/sennaya4.webp'),

                    ],
                    title: 'Очистка кровли бизнес центра на Сенной площади',
                    description: '500 м2 кровли очищено за 2 дня силами 3 альпинистов. Проведение работ осложнялось оживлённым движением пешеходов и автотранспорта, но всё прошло без происшествий, как и всегда'
                },

                {
                    mainImg: require('../../../assets/img/ikea.webp'),
                    photos: [
                        require('../../../assets/img/dima-ikea.webp'),
                        require('../../../assets/img/oleg-ikea.webp'),
                        require('../../../assets/img/dima-ikea2.webp'),

                    ],
                    title: 'Герметизация стыков на ДЦ Икеа',
                    description: '6500 м.п. стыков сэндвич-панелей загерметизированно в дистрибьюторском центре Икеа в д. Есипово'
                },

                {
                    mainImg: require('../../../assets/img/sergey-king.webp'),
                    photos: [
                        require('../../../assets/img/dima-king.webp'),
                        require('../../../assets/img/zheniy-king.webp'),

                    ],
                    title: 'Очистка силоса на заводе Фосфорит',
                    description: 'Очистка двух силосных баков на кингисеппском заводе Фосфорит'
                },
            ]
        }
    }

}
</script>

<style>

</style>