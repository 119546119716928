<template>
    <div class="uk-section section-who" id="who">
        <div class="uk-container">
            <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                <div class="uk-card-media-left uk-cover-container">
                    <img :src="img" alt="" uk-cover>
                    <canvas width="600" height="400"></canvas>
                </div>
                <div>
                    <div class="uk-card-header">
                        <h3 class="uk-card-title">{{title}}</h3>
                    </div>
                    <div class="uk-card-body">
                        <p
                        v-for="(item, i) in content"
                        :key="i">
                            {{item}}
                        </p>
                    </div>
                    <div class="uk-card-footer">
                        <div class="uk-align-center uk-flex uk-flex-center uk-flex-wrap">
                            <p class="uk-margin">
                                <a class="uk-button uk-button-default uk-margin-bottom" href="#modal-sections"
                                    uk-toggle>Заказ услуг</a>
                                <a class="uk-button uk-button-primary uk-margin-bottom"
                                    :href="`tel:${this.$contacts.phone.split('-').join('')}`">Позвонить</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Who',
    data () {
        return {
            img: require('../../../assets/img/verbovoi.jpg'),
            title: 'Промальп центр',
            content: [
                'Промальп центр - это команда промышленных альпинистов с многолетним опытом. Выполняем все виды высотных работ.',
                'Благодаря неизменному качеству выполняемых работ и разумной ценовой политике, мы уверены, что сможем стать для вас надёжным подрядчиком и долгосрочным партнёром.'
            ]
        }
    }

}
</script>

<style>

</style>