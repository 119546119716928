<template>
    <div class="uk-section section-services" id="services">
        <div class="uk-container">
            <div class="uk-card uk-card-default" uk-scrollspy="cls:uk-animation-fade; repeat: true">
                <div class="uk-card-header">
                    <h3 class="uk-card-title">Услуги</h3>
                </div>
                <div class="uk-card-body">
                    <ul uk-accordion="multiple: true">
                        <li>
                            <a class="uk-accordion-title" href="#">Фасадные работы</a>
                            <div class="uk-accordion-content">
                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Цена</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ремонт швов</td>
                                            <td>от 240 р/м.п<br>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Окраска</td>
                                            <td>от 50 р/м2</td>
                                        </tr>
                                        <tr>
                                            <td>Шпаклёвка</td>
                                            <td>от 300 р/м2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#">Клининг</a>
                            <div class="uk-accordion-content">
                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Цена</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Мойка окон</td>
                                            <td>от 40 р/м2</td>
                                        </tr>
                                        <tr>
                                            <td>Мойка фасада</td>
                                            <td>от 30 р/м2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#">Монтажные работы</a>
                            <div class="uk-accordion-content">
                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Цена</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Монтаж баннера</td>
                                            <td>от 200 р/м2</td>
                                        </tr>
                                        <tr>
                                            <td>Монтаж рамы для баннера</td>
                                            <td>от 400 р/м.п.</td>
                                        </tr>
                                        <tr>
                                            <td>Монтаж датчиков камер и т.д.</td>
                                            <td>от 1 000 р/шт</td>
                                        </tr>
                                        <tr>
                                            <td>Монтаж навесных фасадов</td>
                                            <td>от 1 600 р/м2</td>
                                        </tr>
                                        <tr>
                                            <td>Монтаж водосточной системы</td>
                                            <td>от 400 р/м.п.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#">Окраска металлоконструкций</a>
                            <div class="uk-accordion-content">
                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Цена</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Окраска</td>
                                            <td>от 70 р/м2</td>
                                        </tr>
                                        <tr>
                                            <td>Огнезащитная обработка</td>
                                            <td>от 350 р/м2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#">Другие виды работ</a>
                            <div class="uk-accordion-content">
                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Цена</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Подъём грузов</td>
                                            <td>от 20 000 р</td>
                                        </tr>
                                        <tr>
                                            <td>Украшение фасадов</td>
                                            <td>от 30 000 р</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title" href="#">Чистка снега</a>
                            <div class="uk-accordion-content">
                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Вид работ</th>
                                            <th>Цена</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Чистка кровли от снега</td>
                                            <td>от 40 р/м2</td>
                                        </tr>
                                        <tr>
                                            <td>Чистка кровли 1,5 метра от края</td>
                                            <td>от 150 р/м.п.</td>
                                        </tr>
                                        <tr>
                                            <td>Удаление сосулек</td>
                                            <td>по запросу</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="uk-card-footer">
                    <div>
                        Указанные цены не являются публичной офертой и указаны исключительно в ознакомительных целях.
                        Для определения окончательной стоимости производства работ необходимо провести осмотр. Осмотр
                        проводится БЕСПЛАТНО
                    </div>
                    <div class="uk-align-center uk-flex uk-flex-center uk-flex-wrap">
                        <p class="uk-margin">
                            <a class="uk-button uk-button-default uk-margin-bottom" href="#modal-sections"
                                uk-toggle>Заказ услуг</a>
                            <a class="uk-button uk-button-primary uk-margin-bottom"
                                :href="`tel:${this.$contacts.phone.split('-').join('')}`">Позвонить</a>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'

}
</script>

<style>

</style>