<template>
    <div class="uk-section section-contacts" id="contacts">
        <div class="uk-container">
            <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                <div>
                    <div class="uk-card-header">
                        <h3 class="uk-card-title">Контакты</h3>
                    </div>
                    <div class="uk-card-body">
                        <table class="uk-table uk-table-striped">
                            <tbody>
                                <tr>
                                    <td>Телефон</td>
                                    <td><a
                                        :href="`tel:${this.$contacts.phone.split('-').join('')}`">{{this.$contacts.phone}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>E-mail</td>
                                    <td><a :href="`mailto:${this.$contacts.email}`">{{this.$contacts.email}}</a></td>
                                </tr>
                                <!-- <tr>
                                        <td>Telegram</td>
                                        <td><a href="https://t.me/ZhigulskiyE">@ZhigulskiyE</a></td>
                                    </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="uk-card-media-right uk-cover-container">
                    <img :src="img" alt="" uk-cover>
                    <canvas width="600" height="400"></canvas>
                    <div class="uk-overlay uk-overlay-default uk-position-center">
                        <form 
                        @submit.prevent="submitForm"
                        :ref="'miniForm'"
                        class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-2@s">
                                <input class="uk-input" type="text" placeholder="Имя" name='name'>
                            </div>
                            <div class="uk-width-1-2@s">
                                <input class="uk-input" type="text" placeholder="Телефон или email *" name="contact" required>
                            </div>

                            <div 
                                v-if="success"
                                class="uk-alert-success">
                                    <p class="uk-margin-right">Сообщение отправлено. Скоро мы с вами свяжемся 
                                        <a 
                                        @click="success = false"
                                        class="uk-margin-left" href="" uk-close></a></p>
                                </div>

                                <div 
                                v-if="error"
                                class="uk-alert-danger">
                                    <p class="uk-margin-right">Отправка не удалась. Попробуйте ещё раз или свяжитесь с нами по телефону 
                                        <a 
                                        @click="success = false"
                                        class="uk-margin-left" href="" uk-close></a></p>
                                </div>
                            <div class="uk-width-1-1@s uk-align-center">
                                <div 
                                v-if="sending"
                                uk-spinner 
                                class="uk-margin-right"></div>
                                <button 
                                :disabled="sending ? true : false"
                                class="uk-button uk-button-primary uk-align-center"
                                type="submit">Отправить</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contacts',

    data () {
        return {
            img: require('../../../assets/img/evg-snow.jpg'),
            sending: false,
            success: false,
            error: false
        }
    },

    methods: {
        submitForm: async function() {
            this.sending = true;
            let response = await fetch('/mail.php', {
            method: 'POST',
            body: new FormData(this.$refs.miniForm)
            });

            if (response.status == 200) {
                this.$refs.miniForm.reset()
                this.success = true 
                setTimeout(() => {
                    this.success = false 
                }, 5000)
            } else {
                this.error = true 
                setTimeout(() => {
                    this.error = false 
                }, 5000)
            }
            this.sending = false;
        }
    }

}
</script>

<style>

</style>