<template>
    <div id="modal-sections" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <form
            :ref="'form'"
            @submit.prevent="submitForm"
            >
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Заказать услугу</h2>
                </div>
                <div class="uk-modal-body uk-grid-small uk-margin uk-padding" uk-grid>
                    <div class="uk-width-1-2@s">
                        <input class="uk-input" type="text" placeholder="Имя" name="name">
                    </div>
                    <div class="uk-width-1-2@s">
                        <input class="uk-input" type="text" placeholder="Телефон или email *" name="contact" required>
                    </div>
                    <div class="uk-width-1-2@s">
                        <input class="uk-input" type="text" placeholder="Название компании" name="company">
                    </div>
                    <div class="uk-width-1-2@s">
                        <div uk-form-custom="target: > * > span:first-child">
                            <select name="select">
                                <option value="">Выбрать услугу...</option>
                                <option value="Фасадные работы">Фасадные работы</option>
                                <option value="Чистка снега">Чистка снега</option>
                                <option value="Монтажные работы">Монтажные работы</option>
                                <option value="Другое">Другое</option>
                            </select>
                            <button class="uk-button uk-button-default" type="button" tabindex="-1">
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div class="uk-width-1-1@s">
                        <textarea class="uk-textarea" placeholder="Сообщение" name="message"></textarea>
                    </div>
                    <div 
                    v-if="success"
                    class="uk-alert-success uk-padding hide">
                        <p class="uk-margin-right">Сообщение отправлено. Скоро мы с вами свяжемся 
                            <a 
                            @click="success = false"
                            class="uk-margin-left" href="" uk-close></a></p>
                    </div>

                    <div 
                    v-if="error"
                    class="uk-alert-danger uk-padding">
                        <p class="uk-margin-right">Отправка не удалась. Попробуйте ещё раз или свяжитесь с нами по телефону 
                            <a 
                            @click="success = false"
                            class="uk-margin-left" href="" uk-close></a></p>
                    </div>
                    <!-- <div class="uk-width-1-1@s">
                        <div class="uk-margin" uk-margin>
                            <div uk-form-custom="target: true">
                                <input type="file">
                                <input class="uk-input uk-form-width-medium" type="text" placeholder="Приложенный файл" disabled>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <div 
                    v-if="sending"
                    uk-spinner 
                    class="uk-margin-right"></div>
                    <button class="uk-button uk-button-default" type="reset">Сбросить</button>
                    <button 
                    :disabled="sending ? true : false"
                    class="uk-button uk-button-primary" 
                    type="submit">Отправить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalForm',

    data () {
        return {
            sending: false,
            success: false,
            error: false
        }

    },

    methods: {
        submitForm: async function() {
            this.sending = true
            let response = await fetch('/mail.php', {
            method: 'POST',
            body: new FormData(this.$refs.form)
            });

            if (response.status == 200) {
                this.$refs.form.reset()
                this.success = true 
                setTimeout(() => {
                    this.success = false 
                }, 5000)
            } else {
                this.error = true 
                setTimeout(() => {
                    this.error = false 
                }, 5000)
            }
            this.sending = false
        }
    }

}
</script>

<style>
</style>