<template>
  <div>
    <main-header/>

    <main-content/>

    <main-footer/>

    <main-navigation/>

    <modal-dialog/>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from '@/components/home/MainHeader.vue'
import MainContent from '@/components/home/MainContent.vue'
import MainFooter from '@/components/home/MainFooter.vue'
import MainNavigation from '@/components/home/MainNavigation.vue'
import ModalDialog from '@/components/home/ModalDialog.vue'

export default {
  name: 'Home',
  components: {
    MainHeader,
    MainContent,
    MainFooter,
    MainNavigation,
    ModalDialog
  }
}
</script>
