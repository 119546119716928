<template>
    <div class="uk-section section-advantages" id="advantages">
        <div class="uk-container">
            <div class="uk-card uk-card-default" uk-scrollspy="cls:uk-animation-fade; repeat: true">
                <div class="uk-card-header">
                    <h3 class="uk-card-title">{{title}}</h3>
                </div>
                <div class="uk-card-body">
                    <div class="uk-grid-small uk-child-width-1-3@m uk-text-center" uk-grid>
                        <div v-for="(item, i) in features" :key="i" class="uk-card">
                            <div class="uk-card-body">
                                <h3>
                                    {{item.title}}
                                </h3>
                                <img :src="item.img" alt="" width="90" height="auto">
                                <p>
                                    {{item.description}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Portfolio />
                </div>
                <div class="uk-card-footer">
                    <div class="uk-align-center uk-flex uk-flex-center uk-flex-wrap">
                        <p class="uk-margin">
                            <a class="uk-button uk-button-default uk-margin-bottom" href="#modal-sections"
                                uk-toggle>Заказ услуг</a>
                            <a class="uk-button uk-button-primary uk-margin-bottom"
                                :href="`tel:${this.$contacts.phone.split('-').join('')}`">
                                Позвонить</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Portfolio from './Portfolio.vue'

export default {
    name: 'Advantages',
    components: {
        Portfolio
    },
    
    data () {
        return {
            title: 'Почему мы',
            features: [
                {
                    title: 'Опыт',
                    description: 'Средний стаж членов нашей команды 7 лет. Мы совершенно точно знаем не только что делать, но и как это делать',
                    img: require('../../../assets/img/star-icon.png')
                },
                {
                    title: 'Безопасность',
                    description: 'Safety first - для нас это не пустой звук. Безусловная безопасность на любом этапе проведения работ',
                    img: require('../../../assets/img/star-icon.png')
                },
                {
                    title: 'Гарантия',
                    description: 'Отвечаем за качество. Даём гарантию на все выполненные работы.',
                    img: require('../../../assets/img/star-icon.png')
                }
            ],
        }
    }
}
</script>

<style>

</style>